import './Tec.css'

function Tec(props) {
    return (
        <>
            <span className='tec text-white'>{props.tec}</span>
        </>
    );
}

export default Tec;